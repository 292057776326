import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  RecaptchaProvider,
  OutboundLink,
  InternalLink,
  CopyrightLabel,
  FishermanBanner,
} from "@bluefin/components";
import { Header, Button, List } from "semantic-ui-react";
import { graphql, StaticQuery } from "gatsby";

import { getComponentFiles } from "../utils/utils";
import "semantic-ui-less/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage {
          nodes {
            slug
            description
          }
        }
        fishermanBusiness {
          name
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          brandIdentity
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            _id
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
        bookingUrl: fishermanBusinessWebsitePage(
          title: { regex: "/Book Now|Make An Appointment/" }
          pageType: { eq: "External" }
        ) {
          url
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        bookingUrl,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://cutsbymakenna.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://cutsbymakenna.com"}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment />
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <footer>
                <div className={"business-name-container"}>
                  <Header as={"h2"} content={fishermanBusiness.name} />
                  <Button
                    as={OutboundLink}
                    to={bookingUrl.url}
                    event={{
                      category: "Booking",
                      action: "Schedule Appointment Intent",
                    }}
                    primary={true}
                  >
                    Book Now
                  </Button>
                </div>
                <div>
                  <List className={"anchors-container"}>
                    <List.Item to={"#about"} as={InternalLink}>
                      About
                    </List.Item>
                    <List.Item to={"#image-gallery"} as={InternalLink}>
                      Gallery
                    </List.Item>
                  </List>
                </div>
              </footer>
            </RecaptchaProvider>
          </div>
          <CopyrightLabel
            company={fishermanBusiness.name}
            phrase={"All Rights Reserved"}
            privacyPolicyLink={
              <OutboundLink
                to={
                  "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                }
                label={"Privacy Policy"}
                eventLabel={"copyright-privacy-policy"}
              />
            }
          />
          <FishermanBanner brand={fishermanBusiness.brandIdentity} />
        </React.Fragment>
      );
    }}
  />
);
